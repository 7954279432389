import {createBrowserRouter } from "react-router-dom";
import HomePage from "../Pages/Home/HomePage";
import AboutPage from "../Pages/About/AboutPage";
import CVPage from "../Pages/CV/CVPage";
import ProjectsPage from "../Pages/Projects/ProjectsPage";
import ContactPage from "../Pages/Contact/ContactPage";
import BlogLayout from "../Components/Common/Layouts/BlogLayout/BlogLayout";
import ErrorPage from "../Pages/Error/ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        element:<BlogLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element:<HomePage />,
            },
            {
                path: "about",
                element:<AboutPage />,
            },  
            {
                path: "cv",
                element:<CVPage />,
            },
            {
                path: "projects",
                element:<ProjectsPage />,
            },
            {
                path: "contact",
                element:<ContactPage />,
            },
        ]
    }
]);

export default router;