const navbarContents = {
    TR: {
        home: "Ana Sayfa",
        about: "Hakkımda",
        cv: "Özgeçmiş",
        projects: "Projeler",
        contact: "İletişim"
    },

    EN: {
        home: "Home",
        about: "About ",
        cv: "CV",
        projects: "Projects",
        contact: "Contact"
    }
}

export default navbarContents