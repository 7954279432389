const projectsPageContents = {
    TR: {
        pageHeader: "Projeler",
        firstProjectName: "Mesajlaşma Uygulaması",
        firstProjectDescription: "(Geliştiriliyor)",
        secondProjectName: "Place Vault",
        secondProjectDescription: "(Google Play Storedan Erişilebilir)",
        thirdProjectName: "Not Defteri",
        thirdProjectDescription: "(Google Play Storedan Erişilebilir)",
        fourthProjectName: "Video & Müzik İndirme Uygulaması",
        fourthProjectDescription: "(Ziyaret Et)",
        fifthProjectName: "Döviz Birim Çevirici",
        fifthProjectDescription: "(Ziyaret Et)",
        sixthProjectName: "Metin Düzenleyici",
        sixthProjectDescription: "(Windows için İndir)"
    },

    EN: {
        pageHeader: "Projects",
        firstProjectName: "Messaging App",
        firstProjectDescription: "(Developing)",
        secondProjectName: "Place Vault",
        secondProjectDescription: "(Available on Google Play Store)",
        thirdProjectName: "Notebook App",
        thirdProjectDescription: "(Available on Google Play Store)",
        fourthProjectName: "Video & Music Downloader",
        fourthProjectDescription: "(Visit)",
        fifthProjectName: "Currency Converter",
        fifthProjectDescription: "(Visit)",
        sixthProjectName: "Text Editor",
        sixthProjectDescription: "(Download for Windows)"
    }
}

export default projectsPageContents