import classes from "./HomePage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { blogActions } from "../../Store/BlogSlice";
import { Typewriter, Cursor } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { useEffect } from "react";
import homePageContents from "../../contents/homePageContents";

const HomePage = () => {
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    const dispatch = useDispatch();
    
    const info = homePageContents[BlogLanguage].IamA;
    const jobs = [
        homePageContents[BlogLanguage].softwareEngineer,
        homePageContents[BlogLanguage].webDeveloper,
        homePageContents[BlogLanguage].mobileAppDeveloper
    ];

    
    useEffect(()=>{
        dispatch(blogActions.setCurrentPage("home"));
    }, [BlogLanguage,dispatch]);

    useEffect(()=>{

    }, [BlogLanguage]);
    return (    
        <motion.div className={classes.HomePageDiv}
            variants= {{
                hidden: {opacity: 0},
                visible: {opacity: 1}
            }}
            initial= "hidden"
            animate= "visible"
            exit= "hidden">
            <div className={classes.CenterDiv}>
                <h2 className={classes.PersonelName}>Mustafa Doruk ÇİL</h2> 
                <p className={classes.PersonelInfo}>
                {info}<span className={classes.Job}>
                <Typewriter
                    words={jobs}
                    loop={Infinity}
                    cursor
                    cursorStyle=""
                    typeSpeed={50}
                    deleteSpeed={50}
                    delaySpeed={1500}
                    />
                </span>
                <Cursor />
                </p>
            </div>
        </motion.div>
    );
};

export default HomePage;