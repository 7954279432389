const cvPageContents = {
    TR: {
        cvHeader: "Özgeçmiş",
        cvLinkName: "İndir",
        cvName: "Mustafa_Doruk_Cil_CV_TR.pdf",
        educationHeader: "Eğitim",
        educationFirstLine: "Lisans",
        educationSecondLine: "İstanbul Okan Üniversitesi",
        educationThirdLine: "(Devam Ediyor)",
        educationFourthLine: "Genel Not Ortalaması: 3.82",
        foreignLanguagesHeader: "Yabancı Diller",
        firstForeignLanguage: "İngilizce",
        secondForeignLanguage: "Almanca",
        programmingLanguagesHeader: "Programlama Dilleri",
        firstProgrammingLanguage: "Python",
        secondProgrammingLanguage: "Javascript",
        thirdProgrammingLanguage: "Node JS (Javascript)",
        fourthProgrammingLanguage: "Type Script",
        fifthProgrammingLanguage: "Java",
        sixthProgrammingLanguage: "PHP",
        databaseHeader: "Database Bilgisi",
        firstDatabaseLine: "SQL",
        secondDatabaseLine: "Mongo DB",
        thirdDatabaseLine: "Firebase",
        workingAreaHeader: "Çalışma Alanı",
        workingAreaFirstLine: "Yazılım Mühendisliği",
        workingAreaSecondLine: "Web Programlama",
        workingAreaThirdLine: "Mobil Programlama",
        workingAreaFourthLine: "Siber Güvenlik",
        workingAreaFifthLine: "Arama Motoru Optimizasyonu",
        markupLanguagesHeader: "Biçimlendirme Dilleri",
        firstMarkupLanguage: "HTML",
        secondMarkupLanguage: "CSS",
        frameworkHeader: "Framework ve Kütüphane Bilgisi",
        firstFramework: "React",
        secondFramework: "Express JS",
        thirdFramework: "React Native",
        fourthFramework: "Electron JS",
        fifthFramework: "Next JS",
        sixthFramework: "Selenium",
        seventhFramework: "Numpy",
        eightFrameWork: "Pandas",
        operatingSystemsHeader: "İşletim Sistemleri",
        firstOperatingSystem: "Windows",
        secondOperatingSystem: "Linux",
        thirdOperatingSystem: "Android",
    },

    EN: {
        cvHeader: "CV",
        cvLinkName:"Download",
        cvName: "Mustafa_Doruk_Cil_CV_EN.pdf",
        educationHeader: "Education",
        educationFirstLine: "Bachelor",
        educationSecondLine: "Istanbul Okan University",
        educationThirdLine: "(Continue)",
        educationFourthLine: "GPA: 3.82",
        foreignLanguagesHeader: "Foreign Languages",
        firstForeignLanguage: "English",
        secondForeignLanguage: "German",
        programmingLanguagesHeader: "Programming Languages",
        firstProgrammingLanguage: "Python",
        secondProgrammingLanguage: "Javascript",
        thirdProgrammingLanguage: "Node JS (Javascript)",
        fourthProgrammingLanguage: "Type Script",
        fifthProgrammingLanguage: "Java",
        sixthProgrammingLanguage: "PHP",
        databaseHeader: "Database Knowledge",
        firstDatabaseLine: "SQL",
        secondDatabaseLine: "Mongo DB",
        thirdDatabaseLine: "Firebase",
        workingAreaHeader: "Fields of Study",
        workingAreaFirstLine: "Software Engineering",
        workingAreaSecondLine: "Web Programming",
        workingAreaThirdLine: "Mobil Programming",
        workingAreaFourthLine: "Cyber Security",
        workingAreaFifthLine: "Search Engine Optimization",
        markupLanguagesHeader: "Markup Languages",
        firstMarkupLanguage: "HTML",
        secondMarkupLanguage: "CSS",
        frameworkHeader: "Framework and Library Knowledge",
        firstFramework: "React",
        secondFramework: "Express JS",
        thirdFramework: "React Native",
        fourthFramework: "Electron JS",
        fifthFramework: "Next JS",
        sixthFramework: "Selenium",
        seventhFramework: "Numpy",
        eightFrameWork: "Pandas",
        operatingSystemsHeader: "Operating Systems",
        firstOperatingSystem: "Windows",
        secondOperatingSystem: "Linux",
        thirdOperatingSystem: "Android",
    }
}

export default cvPageContents