import classes from "./PageIcon.module.css";
//Home
import { FaHome } from "react-icons/fa"
//About
import { IoPerson } from "react-icons/io5";
//CV
import { GrDocumentUser } from "react-icons/gr";
//Projects
import { CiServer } from "react-icons/ci";

//Contact
import { BiSolidContact } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//Language Content
import navbarContents from "../../../../contents/navbarContents";
const PageIcon = (props) => {
    const BlogLanguage = useSelector(state => state.BlogLanguage);

    let href;
    let icon;
    let pageName;
    if(props.pageName === "home")
    {        
        pageName = navbarContents[BlogLanguage].home;
        href="/";
        icon = <FaHome className={classes.Icon} /> 
    }
    else if (props.pageName === "about")
    {
        pageName = navbarContents[BlogLanguage].about;
        href="about";
        icon = <IoPerson className={classes.Icon} /> 
    }
    else if (props.pageName ==="cv")
    {
        pageName = navbarContents[BlogLanguage].cv;
        href="cv";
        icon = <GrDocumentUser className={classes.Icon} /> 
    }
    else if(props.pageName === "projects")
    {
        pageName = navbarContents[BlogLanguage].projects;
        href="projects";
        icon = <CiServer className={classes.Icon} /> 
    }
    else if(props.pageName === "contact")
    {
        pageName = navbarContents[BlogLanguage].contact;
        href="contact";
        icon = <BiSolidContact className={classes.Icon} /> 
    }

    let cssClasses = classes.PageLink;
    if(props.active)
    {
        cssClasses = classes.PageLink + " "+classes.active;
    }

    return (
        <div className={classes.PageIconDiv}>
            <Link 
                className={cssClasses} 
                to={href}
                alt={props.pageName}>
                {icon}
                <p className={classes.PageInfo}>{pageName}</p>
            </Link>
        </div>
    );
};

export default PageIcon;