import classes from "./ProjectsPage.module.css";
import { useDispatch,useSelector } from "react-redux";
import { blogActions } from "../../Store/BlogSlice";
import ProjectDiv from "../../Components/ProjectsPage/ProjectDiv/ProjectDiv";
import ProjectDivContainer from "../../Components/ProjectsPage/ProjectDivContainer/ProjectDivContainer";
import MessagingAppImage from "../../assets/ProjectImages/MessagingAppImage.png";
import PlaceVaultAppImage from "../../assets/ProjectImages/PlaceVault.png";
import NotebookAppImage from "../../assets/ProjectImages/NotebookAppImage.png";
import MusicVideoDownloaderImage from "../../assets/ProjectImages/VideoMusicDownloader.png";
import CurrencyConverterImage from "../../assets/ProjectImages/CurrencyConverterImage.png";
import TextEditorImage from "../../assets/ProjectImages/TextEditorImage.jpg";
import { motion } from "framer-motion";
import { useState,useEffect } from "react";
import TextEditor from "../../assets/Projects/TextEditor.rar";
import projectsPageContents from "../../contents/projectsPageContents";

const ProjectsPage = () => {
    const BlogLanguage = useSelector(state=>state.BlogLanguage);

    const context = projectsPageContents[BlogLanguage]

    const variants = {
        upperInitial:{ y: -100, opacity: 0 },
        upperAnimate:{ y: 0, opacity: 1 },
    };
    const [isAnimated,setIsAnimated] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(blogActions.setCurrentPage("projects"));
        setIsAnimated(true);
        const timeoutId = setTimeout(() => {
            setIsAnimated(false);
        }, 300);
        
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line
    },[BlogLanguage,dispatch]);

    const redirectHandler = (link) => {
        window.open(link,"_blank","noopener,noreferrer");
    };

    const downloadHandler = () =>
    {
        const link = document.createElement('a');
        link.href = TextEditor;
        link.download = "Text Editor by zThyphon Downloader.rar";
        link.rel = "noopener noreferrer";

        document.body.appendChild(link);
    
        link.click();
    
        document.body.removeChild(link);
    };
    return (
        <div className={classes.OuterDiv}>
            <div className={classes.InnerDiv}>
                <motion.h2 
                    className={classes.PageHeader}
                    initial={variants.upperInitial}
                    animate={isAnimated ? variants.upperInitial: variants.upperAnimate}
                    exit={variants.upperInitial}>{context.pageHeader}</motion.h2>
                <ProjectDivContainer>
                    <ProjectDiv 
                        image={MessagingAppImage} 
                        appName={context.firstProjectName}
                        disable={true} 
                        description={context.firstProjectDescription}
                        isAnimated={isAnimated} 
                    />
                    <ProjectDiv 
                        image={PlaceVaultAppImage} 
                        appName={context.secondProjectName}
                        description={context.secondProjectDescription} 
                        isAnimated={isAnimated}
                        onClick={()=>{redirectHandler("https://play.google.com/store/apps/details?id=com.zthyphon.PlaceVault")}}     
                    />

                    <ProjectDiv 
                        image={NotebookAppImage} 
                        appName={context.thirdProjectName}
                        description={context.thirdProjectDescription} 
                        isAnimated={isAnimated}
                        onClick={()=>{redirectHandler("https://play.google.com/store/apps/details?id=com.notebook_app")}} 
                    />

                </ProjectDivContainer>
                <ProjectDivContainer>
                    <ProjectDiv 
                        image={MusicVideoDownloaderImage} 
                        appName={context.fourthProjectName}
                        description={context.fourthProjectDescription}
                        isAnimated={isAnimated}
                        onClick={()=>{redirectHandler("https://downloader-by-zthyphon.web.app/video")}} 
                    />

		            <ProjectDiv 
                        image={CurrencyConverterImage} 
                        appName={context.fifthProjectName}
                        description={context.fifthProjectDescription}
                        isAnimated={isAnimated}
                        onClick={()=>{redirectHandler("https://currency-converter-by-zthyphon.web.app/")}}
                    />

                    <ProjectDiv 
                        image={TextEditorImage} 
                        appName={context.sixthProjectName}
                        description={context.sixthProjectDescription}
                        isAnimated={isAnimated}
                        onClick={downloadHandler} 
                    />
                </ProjectDivContainer>
            </div>
        </div>
    );
};

export default ProjectsPage;
