import classes from "./ContactPage.module.css";
import { useSelector,useDispatch } from "react-redux";
import { blogActions } from "../../Store/BlogSlice";
import InputLabel from "../../Components/Contact/Elements/InputLabel/InputLabel";
import Input from "../../Components/Contact/Elements/Input/Input";
import TextArea from "../../Components/Contact/Elements/TextArea/TextArea";
import ContactButton from "../../Components/Contact/Elements/ContactButton/ContactButton";
import { useState,useEffect } from "react";
import { ClipLoader } from "react-spinners";
import nullValidation from "../../functions/nullValidation";
import { motion } from "framer-motion";
import contactPageContents from "../../contents/contactPageContents";

const ContactPage = () => {
    const BlogLanguage = useSelector(state=>state.BlogLanguage);
    const dispatch = useDispatch();
    const [nameInfo, setNameInfo] = useState("");
    const [emailInfo, setEmailInfo] = useState("");
    const [titleInfo, setTitleInfo] = useState("");
    const [descriptionInfo, setDescriptionInfo ] = useState("");
    const [isLoading, setIsLoading ] = useState(false);

    const pageText = contactPageContents[BlogLanguage];

    useEffect(()=>
    {
        dispatch(blogActions.setCurrentPage("contact"));

        // eslint-disable-next-line
    },[BlogLanguage,dispatch]);

    const emailChangeHandler = (event) => {
        setEmailInfo(event.target.value);
    };

    const titleChangeHandler = (event) => {
        setTitleInfo(event.target.value);
    };

    const descriptionChangeHandler = (event) => {
        setDescriptionInfo(event.target.value);
    };

    const nameChangeHandler = (event) => {
        setNameInfo(event.target.value);
    };

    const sendCommentHandler = async(event) => {
        event.preventDefault();
        const isValid = nullValidation([nameInfo, emailInfo, titleInfo, descriptionInfo]);
        if(!isValid)
        {
            const alertMessage = pageText.pleaseEnterInputs;
            alert(alertMessage);
        }
        else
        {
            setIsLoading(true);
            const url = "https://api.mustafablog.net/comment/sendComment";
            
            const response = await fetch(url,
                {   
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        senderName: nameInfo,
                        senderEmail: emailInfo,
                        commentTitle: titleInfo,
                        commentDescription: descriptionInfo
                    }),
                });

            if(response.status===201)
            {
                setNameInfo("");
                setEmailInfo("");
                setTitleInfo("");
                setDescriptionInfo("");
                
                const commentInfo = pageText.contactMailHasSent;
                alert(commentInfo);
            }
            else
            {
                const alertMessage = pageText.mailSentError;
                alert(alertMessage);
            }
            setIsLoading(false);
        }
    };  

    let content;
    if(!isLoading) 
    {
        content = (
        <motion.div className={classes.ContactDiv}
            variants= {{
                hidden: {opacity: 0},
                visible: {opacity: 1}
            }}
            initial= "hidden"
            animate= "visible"
            exit= "hidden">
            <h2 className={classes.ContactHeader}>{pageText.headerText}</h2>
            <form onSubmit={sendCommentHandler}>
                <InputLabel text={pageText.nameText} />
                <Input 
                    type="text"
                    name="name"
                    placeholder={pageText.namePlaceHolder}
                    onChange={nameChangeHandler}
                />
                <InputLabel text={pageText.emailText} />
                <Input 
                    type="email"
                    name="email"
                    placeholder={pageText.emailPlaceHolder}
                    onChange={emailChangeHandler}
                />
                <InputLabel text={pageText.titleText} />
                <Input 
                    type="text"
                    name="title"
                    placeholder={pageText.titlePlaceHolder}
                    onChange={titleChangeHandler}
                />
                <InputLabel text={pageText.descriptionText} />
                <TextArea 
                    placeholder={pageText.descriptionPlaceHolder}
                    onChange={descriptionChangeHandler} />
                <ContactButton text={pageText.buttonText} />
            </form>
        </motion.div>
    );}
    else
    {
        content= <ClipLoader color={"#00FBFF"} size={100} />
    }
    return (
        <div className={classes.ContactPageDiv}>
            {content}
        </div>
    );
};

export default ContactPage;
