const contactPageContents = {
    TR: {
        headerText: "İLETİŞİM",
        nameText: "İsim & Soyisim",
        namePlaceHolder: "İsim Girin",
        emailText: "E-Posta",
        emailPlaceHolder: "E-Posta Girin",
        titleText: "Başlık",
        titlePlaceHolder: "Başlık Girin",
        descriptionText: "Açıklama",
        descriptionPlaceHolder: "Açıklama Girin",
        buttonText: "Gönder",
        pleaseEnterInputs: "Lütfen tüm bilgilerin girin.",
        contactMailHasSent: "İletişim Maili Başarıyla Gönderildi.",
        mailSentError: "Sunucuda bir sorun oluştu."
    },

    EN: {
        headerText: "CONTACT ME",
        nameText: "First Name & Last Name",
        namePlaceHolder: "Enter Name",
        emailText: "Email",
        emailPlaceHolder: "Enter Email",
        titleText: "Title",
        titlePlaceHolder: "Enter Title",
        descriptionText: "Description",
        descriptionPlaceHolder: "Enter Description",
        buttonText: "Send",
        pleaseEnterInputs: "Please enter inputs.",
        contactMailHasSent: "Contact Mail has been sent Successfully.",
        mailSentError: "An error occured on the server."
    }
}

export default contactPageContents