const homePageContents = {
    TR: {
        softwareEngineer: "Yazılım Mühendisi",
        webDeveloper: "Web Geliştirici",
        mobileAppDeveloper: "Mobil Uygulama Geliştirici",
        IamA: "",
    },

    EN: {
        softwareEngineer: "Software Engineer",
        webDeveloper: "Web Developer",
        mobileAppDeveloper: "Mobile App Developer",
        IamA: "I am a "
    }
}

export default homePageContents