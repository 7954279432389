const aboutMeContents = {
    TR: {
        aboutHeader: "Hakkımda",
        aboutDescription: "Kod evrenime hoşgeldiniz. Ben, dijital çözümler oluşturmanın sanatını öğrenme misyonu üzerine yola çıkmış tutkulu bir yazılım mühendisliği öğrencisiyim. Çeşitli programlama dillerinde ve en yeni teknolojilerde uzmanlaşmış biriyim. İşbirliği benim alanım, çünkü en iyi çözümler zihinler birleştikçe ortaya çıkar.",
        jobDescription: "Yazılım Mühendisi & Full Stack Geliştirici",
        birthDayFieldName: "Doğum Günü",
        birthDayFieldValue: "18 Mart 2002",
        townCityFieldName: "İlçe/İl",
        townCityFieldValue: "Kartal/İstanbul",
        phoneFieldName: "Telefon",
        degreeFieldName: "Eğitim",
        degreeFieldValue: "Yazılım Mühendisliği",
        freelanceFieldName: "Serbest Çalışma",
        freelanceFieldValue: "Uygun",
        softwareEngineerText: "Yazılım Mühendisi",
        webDeveloperText: "Web Geliştirici",
        mobileDeveloperText: "Mobil Geliştirici",
        teamPlayerText: "Takım Oyuncusu"
    },

    EN: {
        aboutHeader: "About Me",
        aboutDescription: 
        "Welcome to my corner of the coding universe! I'm a passionate software engineering student on a mission to master the art of creating digital solutions. Fueled by curiosity and a love for challenges, I specialize in diverse programming languages and cutting-edge technologies. Collaboration is my forte, as I believe the best solutions emerge when minds unite.",
        jobDescription:
        "Software Engineer & Full Stack Developer",
        birthDayFieldName: "Birthday",
        birthDayFieldValue: "March 18 2002",
        townCityFieldName: "Town-City",
        townCityFieldValue: "Kartal/Istanbul",
        phoneFieldName: "Phone",
        degreeFieldName: "Degree",
        degreeFieldValue: "Bachelor (Continue)",
        freelanceFieldName: "Freelance",
        freelanceFieldValue: "Available",
        softwareEngineerText: "Software Engineer",
        webDeveloperText: "Web App Developer",
        mobileDeveloperText: "Mobile App Developer",
        teamPlayerText: "Team Player"
    }
}

export default aboutMeContents